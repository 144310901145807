import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation
import { FaBarsStaggered, FaXmark } from "react-icons/fa6";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup: Remove scroll event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  const navItems = [
    { link: "Home", path: "/" },
    { link: "About", path: "/about-us" },
    { link: "Services", path: "/service" },
    // { link: "Blog", path: "/blog" },
    { link: "Contact", path: "/contact-us" }
  ];

  return (
    <header className="w-full bg-dark fixed top-0 left-0 right-0 transition-all ease-in duration-300 z-50">
  <nav className={`lg:px-20 px-4 ${isSticky ? "sticky top-0 left-0 right-0 bg-dark transition-all duration-300 ease-in" : "transition-all duration-300 ease-in"}`}>
    <div className="flex justify-between items-center text-base gap-8 py-3">
      <a href='/'>
        <div className='text-white w-[125px] h-[55px] z-20'>
          <img className="w-full h-full object-contain" src='/logo.jpeg' alt='logo' />
        </div>
      </a>

      {/* Desktop Menu */}
      <ul className="md:flex space-x-12 hidden navitems">
        {navItems.map(({ link, path }) => (
          <Link 
            to={path} 
            key={path} 
            className="block text-base text-white uppercase hover:text-orange cursor-pointer">
            {link}
          </Link>
        ))}
      </ul>

      {/* Desktop Icon */}
      <div className="space-x-12 hidden lg:flex items-center">
        <button className="bg-transparent text-white p-2 border rounded-full">
          <FaBarsStaggered className="w-4 h-4 hover:text-orange" />
        </button>
      </div>

      {/* Mobile Menu Button */}
      <div className="md:hidden">
        <button
          className="text-white focus:outline-none transition-all duration-300 ease-in"
          onClick={toggleMenu}
        >
          {isMenuOpen ? <FaXmark className="h-6 w-6" /> : <FaBarsStaggered className="h-6 w-6" />}
        </button>
      </div>
    </div>

    {/* Mobile Menu */}
    <div className={`space-x-4 px-4 mt-14 py-7 bg-dark transition-all duration-300 ease-in ${isMenuOpen ? "block fixed top-0 right-0 left-0" : "hidden"}`}>
      {navItems.map(({ link, path }) => (
        <Link 
          to={path} 
          key={path} 
          className="block text-base text-white uppercase hover:text-orange cursor-pointer">
          {link}
        </Link>
      ))}
    </div>
  </nav>
</header>
  );
};

export default Navbar;


// import React, { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom';
// import {FaBarsStaggered, FaXmark} from "react-icons/fa6";
// const Navbar = () => {
//     const [isMenuOpen, SetIsMenuOpen] = useState(false);
//     const [isSticky, SetIsSticky] = useState(false);
     
//     const toggleMenu =() => {
//         SetIsMenuOpen(!isMenuOpen);
//     }
//     useEffect(() => {
//         const handleScroll = () => {
//             if (window.scrollY > 100) {

//             }
//             else{
//                 SetIsSticky(false);
//             }
//         };
//         window.addEventListener('scroll', handleScroll);
//         return() => {
//             window.addEventListener('scroll', handleScroll);
//         }
//     })
//     const navItems = [
// {link: "Home", path: "home"},
// {link: "About", path: "about"},
// {link: "Services", path: "services"},
// {link: "Blog", path: "blog"},
// {link: "Contact", path: "contact"}
//     ]
//   return (
//    <header className='w-full bg-dark fixed top-0 left-0 right-0 transition-all ease-in duration-300'>
//     <nav className={`py-4 lg:px-24 px-4 ${isSticky ? "sticky top-0 left-0 right-0 bg-dark transition-all duration-300 ease-in" : "transition-all duration-300 ease-in"}`}>
//        <div className='flex justify-between items-center text-base gap-8'>
//         <a href='/' className='text-white text-3xl font-bold'> <span className='text-orange'>Troupe</span>Digital</a>
//         <ul className='md:flex space-x-12 hidden navitems'>
//             {
//               navItems.map(({link,path}) => <Link to={path} activeclassName='active' spy={true} smooth={true} offset={-100} key={path} className='block text-base text-white uppercase hover:text-orange cursor-pointer' >
//               {link} </Link>) 
//             }
//         </ul>
         
//         <div className='space-x-12 hidden lg:flex items-center'>
//         <button className='bg-transparent text-white p-2 border rounded-full'>
//             <FaBarsStaggered className='w-4 h-4 hover:text-orange'/>
//         </button>
//         </div>
//         <div className='md:hidden'>
//             <button className='text-white focus:outline-none  transition-all duration-300 ease-in'
//             onClick={toggleMenu}>
//              {
//                 isMenuOpen ? <FaXmark className='h-6 w-6'/> : <FaBarsStaggered className='h-6 w-6'/>
//              }

//             </button>
//         </div>
//         </div> 
//         <div className={`space-x-4 px-4 mt-14 py-7 bg-dark transition-all duration-300 ease-in ${isMenuOpen ? "block fixed top-0 right-0 left-0" : "hidden"}`}>
// {
//     navItems.map(({link,path}) => <Link to={path} activeclassName='active' spy={true} smooth={true} offset={-100} key={path} className='block text-base text-white uppercase hover:text-orange cursor-pointer' >
//               {link} </Link>) 
// }
//         </div>
//     </nav>

//    </header>
//   )
// }

// export default Navbar
