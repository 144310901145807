import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="bg-dark min-h-screen  items-center justify-center py-10 lg:px-8 ">
            <div className="bg-dark w-full rounded-lg shadow-lg p-8 md:p-16 text-white">
                <h1 className="text-3xl font-bold mb-8 text-center">Privacy Policy</h1>
                <p>
                    At TroupeDigital, accessible from https://___, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by TroupeDigital and how we use it.
                </p>
                <p className="mt-4">
                    If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
                </p>
                <p className="mt-4">
                    This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collected in TroupeDigital. This policy is not applicable to any information collected offline or via channels other than this website.
                </p>

                <h2 className="text-2xl font-semibold mt-8">Consent</h2>
                <p className="mt-4">
                    By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                </p>

                <h2 className="text-2xl font-semibold mt-8">Information we collect</h2>
                <p className="mt-4">
                    The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
                </p>
                <p className="mt-4">
                    If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
                </p>
                <p className="mt-4">
                    When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
                </p>

                <h2 className="text-2xl font-semibold mt-8">How We Use Your Information</h2>
                <p className="mt-4">
                    At TroupeDigital, we value the trust you place in us by providing your personal information. We are committed to using this information responsibly and transparently, with your privacy and security as our utmost priority. Here's how we utilize the information we collect:
                </p>
                <ul className="list-disc ml-8 mt-4 space-y-2">
                    <li>Provide, operate, and maintain our website, ensuring a seamless and user-friendly experience for all visitors.</li>
                    <li>Improve, personalize, and expand our website, tailoring content and features to better meet your needs and preferences..</li>
                    <li>Understand and analyze how you use our website, gathering insights to enhance usability and optimize performance.</li>
                    <li>Develop new products, services, features, and functionality, innovating to better serve our community and stay ahead of evolving trends.</li>
                    <li>Communicate with you effectively, whether it's for customer service, updates on website-related matters, or marketing and promotional purposes. We strive to keep you informed and engaged, respecting your communication preferences at all times.</li>
                    <li>Send you emails, delivering relevant content, updates, and offers that campaign with your interests and preferences. You have the option to manage your email subscriptions and preferences at any time.</li>
                    <li>Find and prevent fraud, safeguarding the integrity of our platform and protecting both our users and our business from unauthorized activities.</li>
                    <li>Respect your choices and preferences, ensuring that your personal information is used in accordance with your consent and applicable laws and regulations.</li>
                    <li>Your privacy matters to us, and we are committed to upholding the highest standards of data protection and privacy practices. If you have any questions or concerns about how we use your information, please don't hesitate to contact us.</li>
                </ul>

                <h2 className="text-2xl font-semibold mt-8">Log Files</h2>
                <p className="mt-4">
                    At TroupeDigital, we follow a standard procedure of using log files to ensure the security and performance of our website like many other websites, we utilize log files as part of our hosting services' analytics. These files log visitors' interactions with our website, helping us gather valuable insights into how our site is used and how we can improve it.
                </p>
                <p className="mt-4">
                    The information collected by log files may include:
                </p>
                <ul className="list-disc ml-8 mt-4 space-y-2">
                    <li>Internet Protocol (IP) addresses</li>
                    <li>Browser type</li>
                    <li>Internet Service Provider (ISP)</li>
                    <li>Date and time stamp of visits</li>
                    <li>Referring/exit pages</li>
                    <li>The number of clicks</li>
                </ul>
                <p className="mt-4">
                    It's important to note that this information is not linked to any personally identifiable information. Instead, it is used for various purposes, such as:
                </p>
                <ul className="list-disc ml-8 mt-4 space-y-2">
                    <li>Analyzing trends in website traffic</li>
                    <li>Administering the site and ensuring its smooth operation</li>
                    <li>Tracking users' movement on the website</li>
                    <li>Gathering demographic information about our audience</li>
                </ul>
                <p className="mt-4">
                    By analyzing these trends and patterns, we can better understand our users' preferences and behaviors, allowing us to tailor our content and services to meet their needs effectively.
                </p>
                <p className="mt-4">
                    Your privacy and security are of utmost importance to us. Rest assured that we handle all data collected through log files with the utmost care and in accordance with applicable privacy laws and regulations.        </p>
                <h2 className="text-2xl font-semibold mt-8">Cookies and Web Beacons</h2>
                <p className="mt-4">
                    Like any other website, TroupeDigital uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.
                </p>
                <h2 className="text-2xl font-semibold mt-8">Advertising Partners Privacy Policies</h2>
                <p className="mt-4">
                    You may consult this post to find the Privacy Policy for each of the advertising partners of TroupeDigital.
                </p>
                <p className="mt-4">
                    Third-party ad servers or ad networks use technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on TroupeDigital, which are sent directly to users' browsers. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
                </p>
                <p className="mt-4">
                    Note that TroupeDigital has no access to or control over these cookies that are used by third-party advertisers.
                </p>
                <h2 className="text-2xl font-semibold mt-8">Third-Party Privacy Policies</h2>
                <p className="mt-4">
                    TroupeDigital Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.        </p>
                <p className="mt-4">
                    You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.          </p>
                <h2 className="text-2xl font-semibold mt-8">CCPA Privacy Rights</h2>
                <p className="mt-4">
                    Under the CCPA, among other rights, California consumers have the right to:        </p>

                <p className="mt-4">
                    Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.        </p>
                <p className="mt-4">
                    Request that a business delete any personal data about the consumer that a business has collected.        </p>
                <p className="mt-4">
                    Request that a business that sells a consumer's personal data, not sell the consumer's personal data.        </p>
                <p className="mt-4">
                    If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.        </p>

                <h2 className="text-2xl font-semibold mt-8">GDPR Data Protection Rights</h2>
                <p className="mt-4">
                We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:                </p>

                <p className="mt-4">
                The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.                        </p>
                        <p className="mt-4">
                        The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.     
                       </p>

                       <p className="mt-4">
                       The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
                       </p>
                       <p className="mt-4">
                       The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
                       </p>
                       <p className="mt-4">
                       The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
                       </p>
                       <p className="mt-4">
                       The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                       </p>
                       <p className="mt-4">
                       If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.                       </p>
                <h2 className="text-2xl font-semibold mt-8">Children's Information</h2>
        <p className="mt-4">
        At TroupeDigital, we are deeply committed to ensuring the safety and well-being of children online. Our dedication to protecting their privacy extends to every aspect of our platform, and we actively encourage parents and guardians to take an active role in guiding and supervising their children's online activities.
        </p>
        <p className="mt-4">
        As part of our commitment, TroupeDigital  does not knowingly collect any Personal Identifiable Information from children under the age of 13. We adhere strictly to this policy, and if you suspect that your child has provided any such information on our website, please don't hesitate to reach out to us. We take these matters seriously and will promptly take the necessary steps to address your concerns and remove any inappropriate content from our records.
        </p>
        <p className="mt-4">
        Our approach to child safety goes beyond mere compliance with legal requirements. We continuously assess and enhance our safety protocols to provide a secure online environment for users of all ages.
        </p>
        <p className="mt-4">
        We understand the importance of fostering trust and transparency in our relationships with parents and guardians. By promoting open communication and collaboration, we aim to empower families to navigate the digital landscape safely and responsibly.
        </p>
        <p className="mt-4">
        Rest assured, your child's online safety is our top priority, and we remain steadfast in our commitment to upholding the highest standards of privacy and protection.
        </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
