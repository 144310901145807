import React from 'react'
import { IoArrowForward } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { FaRocket, } from "react-icons/fa";
import { FaBullseye, FaCogs, FaChartLine } from "react-icons/fa";
import { motion } from "framer-motion";
import { Helmet } from 'react-helmet';

const Home = () => {
  // const FeatureCard = ({ icon, title, description }) => {
  //   return (
  //     <div className="flex flex-col items-center">
  //       {icon}
  //       <h3 className="text-xl font-bold mt-4">{title}</h3>
  //       <p className="text-gray-400 mt-2">{description}</p>
  //     </div>
  //   );
  // };

  const ProcessStep = ({ stepNumber, title, description, icon }) => {
    return (
      <motion.div
        className="flex flex-col items-center text-center bg-gray-800 p-6 rounded-lg shadow-lg "
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        viewport={{ once: true }}
      >
        <div className="text-gray-300 mb-4 ">
          <span className="px-4 py-1 border border-gray-600 rounded-full text-sm hover:text-orange">{stepNumber}</span>
        </div>
        <h3 className="text-xl font-bold mb-2 text-orange">{title}</h3>
        {icon}
        <p className="text-gray-400 mt-4">{description}</p>
      </motion.div>
    );
  }
  // const clients = [
  //   { name: 'Outbrain', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Outbrain_logo.svg/2560px-Outbrain_logo.svg.png' },
  //   { name: 'Trello', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/Trello-logo-blue.svg/2560px-Trello-logo-blue.svg.png' },
  //   { name: 'LinkedIn', logo: 'https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png' },
  //   { name: 'Dropbox', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Dropbox_logo_2017.svg/2560px-Dropbox_logo_2017.svg.png' },
  //   { name: 'Appen', logo: 'https://appen.com/wp-content/uploads/2022/10/appen-logo-black.png' },
  //   { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/d/de/Amazon_icon.png' },
  //   { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/1024px-Microsoft_logo.svg.png' },
  //   { name: 'Shopify', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Shopify_logo_2018.svg/2560px-Shopify_logo_2018.svg.png' },
  //   { name: 'AliExpress', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/AliExpress_logo.svg/2560px-AliExpress_logo.svg.png' },
  // ];
  const cardData = [
    {
     
      subtitle: "Growth-Oriented Culture",
      description: "We are invested in our teams growth as much as our clients. A growth-oriented culture fosters continuous learning, development, and improvement. ",
    },
    {
      
      subtitle: "Impactful Work with Global Brands",
      description: "At Troupe Digital, we work with diverse brands across industries, from startups to global brands. ",
    },
    {
     
      subtitle: "Collaborative and Inclusive Environment",
      description: "Our agency thrives on teamwork. We have built a culture where every voice matters.A collaborative and inclusive environment.",
    },
  ];
  return (
    <>
      <Helmet>
      <title>Troupe Digital - Empowering Brands with Data-Driven Digital Advertising Solutions</title>
      <meta  name='description' content='Paid media campaigns for targeted visibility, programmatic advertising for smart, automated ad placement, and social media marketing, branding campaigns'/>
      <meta name='keywords' content='Digital Advertising Agency, Paid Media Services, Programmatic Advertising, Branding Campaigns, Digital Marketing Solutions, Online Advertising Experts, Social Media Advertising, PPC Management, Remarketing Services, WhatsApp Automation for Brands, Data-Driven Advertising, Digital Ad Campaign Optimization, Performance Marketing Agency, Video and Display Advertising' />
    </Helmet>
      <div className='banner cs-style1' 
      style={{ 
       backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/home.png'})`,
       backgroundPosition: 'center',
       backgroundRepeat: 'no-repeat',
       backgroundSize: 'cover',
       width: '100%',
      //  height: '100vh',
       backgroundAttachment: 'fixed'
     }}>
        <div className='cs-shape_1'></div>
        <div className='cs-shape_1'></div>
        <div className='cs-shape_1'></div>


        <div className='px-4 lg:px-24'>
          <h1 className='md:w-2/3 lg:text-6xl sm:text-6xl text-5xl mb-5 text-white font-bold lg:leading-snug sm:leading-snug leading-snug '>
          Transform Clicks into Connections, Ideas into Impact!                     
          </h1>
          <div className='text-white text-lg flex md:flex-row flex-col-reverse md:items-cemter items-start justify-between gap-8'>
            <div>
              <div className='inline-flex items-center gap-2 font-semibold hover:text-orange transition-all duration-300 ease-in'><IoArrowForward className='text-white' /></div>
            </div>
            <div className='md:w-1/2'>
              <p className=''>
              Tell your story a different way! what make us different it’s our service and delivery we not only become better we become the best for the best.
              </p>
            </div>
          </div>
        </div>
        <Link to='/' smooth={true} className='cs-down_btn md:mt-20 mt-8' >.</Link>
      </div>


      <section className="bg-dark text-white py-16">
        <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between gap-2 px-4 lg:px-4">
          {/* Left Image Section */}
          <div className="lg:w-1/2 w-full flex flex-col items-center justify-center space-y-6">
            {/* Image 1 */}
            <div className="rounded-xl overflow-hidden shadow-lg w-96 h-64">
              <img
                src="/assets/home11.png"
                alt="image1"
                className="object-cover w-full h-full"
              />
            </div>
            {/* Image 2 */}
            <div className="rounded-xl overflow-hidden shadow-lg w-96 h-64">
              <img
                src="/assets/home12.png"
                alt="image2"
                className="object-cover w-full h-full"
              />
            </div>
          </div>

          {/* Right Text Content */}
          <div className="lg:w-1/2 w-full flex flex-col justify-center">
            <div className="flex items-center space-x-2">
              <div className="w-12 h-12 rounded-full bg-orange flex items-center justify-center">
                <span className="text-white text-2xl font-bold">↗</span>
              </div>
              <p className="text-gray-400 uppercase text-sm">Your Partner in Digital Transformation</p>
            </div>

            <h1 className="text-5xl font-bold mt-6">
              <span className="bg-yellow-200 text-black px-2">Boost your</span> marketing process with us
            </h1>

            <p className="text-gray-300 text-lg mt-4">
            At Troupe Digital we don’t just run campaigns – we craft experiences that elevate your brand and drive tangible results. As a full-service digital advertising agency, we partner with businesses like yours to navigate the ever-changing digital landscape and unlock new opportunities for growth.
            </p>

            {/* <ul className="space-y-3 mt-6">
              <li className="flex items-center space-x-2">
                <span className="bg-orange rounded-full p-2">
                  <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </span>
                <span>Marketing Strategy & SEO Campaign</span>
              </li>
              <li className="flex items-center space-x-2">
                <span className="bg-orange rounded-full p-2">
                  <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </span>
                <span>Best Customers Experience</span>
              </li>
            </ul> */}

            {/* <button className="bg-black text-white mt-8 px-6 py-3 rounded-md hover:bg-gray-800">
              Learn More →
            </button> */}
          </div>
        </div>
      </section>
      <div className="min-h-screen bg-black text-white">
  {/* Header Section */}
  <div className="container mx-auto px-6 lg:px-14 py-16 flex flex-col md:flex-row items-center">
    
    {/* Left Content Section */}
    <div className="md:w-1/2 flex flex-col space-y-6">
      {/* Our Approach Section */}
      <div className="flex items-center space-x-2">
        <div className="w-12 h-12 rounded-full bg-orange flex items-center justify-center">
          <span className="text-white text-2xl font-bold">↗</span>
        </div>
        <h2 className="text-gray-400 uppercase text-sm">
          Our Approach
        </h2>
      </div>

      {/* Main Heading */}
      <h1 className="text-5xl font-bold leading-tight">
        <span className="bg-yellow-200 text-black px-2">Data-Driven</span> <br /> 
        Creativity-Led, Results-Focused
      </h1>

      {/* Paragraph */}
      <p className="text-gray-400 mt-4 text-lg">
        Our approach to digital advertising is rooted in the perfect balance of data and creativity. We begin by diving deep into the data to understand our audience, industry, and competition.
      </p>
    </div>

    {/* Right Image Section */}
    <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
      <img
        src="/assets/home13.png"
        alt="SEO Illustration"
        className="rounded-lg shadow-lg"
      />
    </div>
  </div>
</div>


      {/* <div className="min-h-screen bg-black text-white">
        <div className="container mx-auto px-14 py-16 flex flex-col md:flex-row items-center">
          <div className="md:w-1/2">
          
            <h2 className="text-orange font-semibold text-xl">
            Our Approach
            </h2> 
            <h1 className="text-5xl font-bold  mt-4">
             <span className="bg-yellow-200 text-black px-2">Data-Driven </span>  <br/> Creativity-Led, Results-Focused
            </h1>
            <p className="text-gray-400 mt-4 text-lg mr-4">
            Our approach to digital advertising is rooted in the perfect balance of data and creativity. We begin by diving deep into the data to understand our audience, industry, and competition. 
            </p>
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
            <img
              src="/assets/home13.png"
              alt="SEO Illustration"
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div> */}


      {/* Working Process Section */}
      <section className="min-h-screen bg-black text-white">
        <div className="container mx-auto px-10 py-16">
          {/* Header */}
          <div className="text-center mb-12">
            <span className="bg-orange text-white px-4 py-1 rounded-full font-semibold text-2xl">
              Working Process
            </span>
            <h2 className="text-4xl font-bold mt-4">
             How Do We Work ?
            </h2>
          </div>

          {/* Steps */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8  ">
            <ProcessStep
              stepNumber="Step 01"
              title="Target Audience"
              description="We focus on brands looking for innovative, data-driven digital marketing solutions that connect with their audience and deliver results."
              icon={<FaBullseye className="text-5xl text-teal-400" />}
            />
            <ProcessStep
              stepNumber="Step 02"
              title="Competitor Analysis"
              description="Digital marketing  evaluating the strategies, strengths, and weaknesses of competing industry to identify opportunities for growth."
              icon={<FaRocket className="text-5xl text-yellow-400" />}
            />
            <ProcessStep
              stepNumber="Step 03"
              title="Strategy & Execution"
              description="Turning strategy into action by implementing campaigns, optimizing content, and using analytics to continuously improve performance."
              icon={<FaCogs className="text-5xl text-purple-400" />}
            />
            <ProcessStep
              stepNumber="Step 04"
              title="Maximize Results"
              description="Data analysis, targeting, and continuous adjustments to achieve the highest return on investment (ROI) and engagement."
              icon={<FaChartLine className="text-5xl text-blue-400" />}
            />
          </div>
        </div>
      </section>
      <div className="bg-dark py-8 px-4 sm:px-6 lg:px-8">
      {/* Header Section */}
      <div className="max-w-3xl mx-auto text-center">
        <h2 className="text-2xl font-semibold text-orange uppercase tracking-widest mb-2">
          Why choose Us?
        </h2>
        <div className="relative inline-block">
          {/* <span className="absolute text-6xl text-orange -top-6 left-0">&ldquo;</span> */}
          <h1 className="text-3xl font-bold text-white mb-4">Shape the Future of Digital Advertising with Troupe Digital</h1>
          {/* <span className="absolute text-6xl text-orange -bottom-8 right-44">&rdquo;</span> */}
        </div>
        <p className="mt-3 text-base text-gray-500">
        At Troupe Digital, we’re more than just a digital advertising agency – we’re a collective of creative thinkers, data-driven strategists, and passionate innovators who thrive on pushing boundaries and delivering results. 
        </p>
      </div>

      {/* Cards Section */}
      {/* <h1 className="text-3xl font-bold text-white text-center mt-4 pl-10">Why Work With Us ?</h1> */}
      <div className="mt-6 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 max-w-7xl mx-auto">
     
        {cardData.map((card, index) => (
          <div
            key={index}
            className=" px-10 p-6 rounded-lg shadow-md hover:shadow-xl hover:bg-gray-800  transform transition-transform duration-300 hover:scale-105"
          >
            <h3 className="text-sm font-semibold text-orange uppercase tracking-wider">
              {card.title}
            </h3>
            <h4 className="mt-2 text-xl font-bold text-orange">{card.subtitle}</h4>
            <p className="mt-4 text-gray-500">{card.description}</p>
          </div>
        ))}
      </div>
    </div>


      {/* <div className=" py-16 px-8 lg:px-24">
        <div className="container mx-auto flex flex-col lg:flex-row items-center">
          
          <div className="lg:w-1/2 w-full mb-8 lg:mb-0">
            <motion.img
              src="https://react.mediacity.co.in/zoso/static/media/partener-two-img-box.474d66145725a9859ac4.png" // Replace with actual image URL
              alt="Global Partner"
              className="rounded-xl shadow-lg w-full h-auto"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            />
          </div>
          
          <div className="lg:w-1/2 w-full lg:pl-16 text-center lg:text-left">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.3 }}
            >
              <span className="bg-orange text-black px-4 py-1 rounded-full font-semibold text-sm inline-block mb-4">
                Global Partner
              </span>
              <h2 className="text-4xl font-bold mb-4 text-white">
                I’ve 1253+ Global Clients <br />
                & lot’s of Project Complete
              </h2>
            </motion.div>
            <div className="mt-8 grid grid-cols-2 sm:grid-cols-3 gap-6 justify-items-center">
              {clients.map((client, index) => (
                <motion.div
                  key={index}
                  className="flex items-center justify-center w-32 h-16"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5, delay: 0.1 * index }}
                >
                  <img src={client.logo} alt={client.name} className="max-h-12" />
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div> */}

      <section className="py-10 bg-dark sm:py-16 lg:py-24">
        <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-5xl">Questions & Answers</h2>
            <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-300">Explore the common questions and answers</p>
          </div>

          <div className="grid grid-cols-1 mt-12 md:mt-20 md:grid-cols-2 gap-y-16 gap-x-20">
            <div className="flex items-start">
              <div className="flex items-center justify-center flex-shrink-0 w-8 h-8 bg-gray-700 rounded-full">
                <span className="text-lg font-semibold text-orange">?</span>
              </div>
              <div className="ml-4">
                <p className="text-xl font-semibold text-white">What services does Troupe Digital offer?</p>
                <p className="mt-4 text-base text-gray-400">Troupe Digital offer a range of digital advertising services, including paid media, programmatic advertising, branding campaigns, and WhatsApp automation. Our solutions are designed to drive performance and elevate your brand’s digital presence.</p>
              </div>
            </div>

            <div className="flex items-start">
              <div className="flex items-center justify-center flex-shrink-0 w-8 h-8 bg-gray-700 rounded-full">
                <span className="text-lg font-semibold text-orange">?</span>
              </div>
              <div className="ml-4">
                <p className="text-xl font-semibold text-white">What platforms does Troupe Digital use for advertising?</p>
                <p className="mt-4 text-base text-gray-400">Troupe Digital leverage major platforms like Google, Meta, TikTok, as well as exclusive inventories, and our in-house programmatic platform, BidBox. This gives us the flexibility to choose the best channels for your campaign’s objectives.</p>
              </div>
            </div>

            <div className="flex items-start">
              <div className="flex items-center justify-center flex-shrink-0 w-8 h-8 bg-gray-700 rounded-full">
                <span className="text-lg font-semibold text-orange">?</span>
              </div>
              <div className="ml-4">
                <p className="text-xl font-semibold text-white">How long does it take to see results?</p>
                <p className="mt-4 text-base text-gray-400">Timelines vary depending on campaign goals, but we typically start seeing actionable results within the first few weeks. Our team works quickly to optimize and scale campaigns based on early data insights.</p>
              </div>
            </div>

            <div className="flex items-start">
              <div className="flex items-center justify-center flex-shrink-0 w-8 h-8 bg-gray-700 rounded-full">
                <span className="text-lg font-semibold text-orange">?</span>
              </div>
              <div className="ml-4">
                <p className="text-xl font-semibold text-white">Does Troupe Digital manage all aspects of the campaign?</p>
                <p className="mt-4 text-base text-gray-400">Troupe Digital manage everything from strategy, creation, execution, optimization, to reporting. This ensures your campaigns are fully supported at every step and continuously improved for better results.
                </p>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center mt-12 md:mt-20">
            <div className="px-8 py-4 text-center bg-gray-800 rounded-full">
              
              <p className="text-gray-50">Didn&apos;t find the answer you are looking for? 
              <Link to='/contact-us' title="Contact our support">
        <span className="text-orange transition-all duration-200 hover:text-orange focus:text-orange hover:underline ml-2">
          Contact our support
        </span>
      </Link></p>
            </div>
          </div>
        </div>
      </section>

     
      {/* <div className='text-white md:mt-[100px] -mt-10 bg-gradient-to-tr from-[#161616] to-[#080808] lg:mx-24 md:py-20 mx-4 py-12 md:px-16 px-6 rounded-xl z-50 flex flex-col md:flex-row justify-between md:item-center gap-12 relative '>
      <div className='bg-[url("")] bg-no-repeat bg-center h-full w-full absolute top-0 left-0 opacity-75'>

      </div>
      <div className='md:w-2/5'>
<h2 className='text-5xl font-bold mb-5'>Our</h2>
<p className='text-gray-300  text-lg'>We Deliver best problem solving solution for our client and peovide finest Finishing product in present and upcoming future</p>
</div>
     <div className='md:w-3/5'>
      <div className='grid sm:grid-cols-2 grid-cols-1 gap-8'>
<div className='flex h-auto items-center gap-3'>
<h4 className='md:text5xl text-4xl font-semibold'>40K</h4>
<div>
  <span className='text-orange font-bold text-lg'>+</span>
<p className='mb-2 text-lg leading-tight text-gray-300'>Global happy clients</p>
</div>
</div>
<div className='flex h-auto items-center gap-3'>
<h4 className='md:text5xl text-4xl font-semibold'>40K</h4>
<div>
  <span className='text-orange font-bold text-lg'>+</span>
<p className='mb-2 text-lg leading-tight text-gray-300'>Global happy clients</p>
</div>
</div>
<div className='flex h-auto items-center gap-3'>
<h4 className='md:text5xl text-4xl font-semibold'>40K</h4>
<div>
  <span className='text-orange font-bold text-lg'>+</span>
<p className='mb-2 text-lg leading-tight text-gray-300'>Global happy clients</p>
</div>
</div>
<div className='flex h-auto items-center gap-3'>
<h4 className='md:text5xl text-4xl font-semibold'>40K</h4>
<div>
  <span className='text-orange font-bold text-lg'>+</span>
<p className='mb-2 text-lg leading-tight text-gray-300'>Global happy clients</p>
</div>
</div>
    
     </div>
      </div>
    </div> */}
    </>
  )
}

export default Home
