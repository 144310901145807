import React from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const About = () => {
 

  return (
    <>
     <Helmet>
      <title>Troupe Digital -Empowering Brands with Data-Driven Digital Advertising Solutions</title>
      <meta  name='description' content='Helping brands thrive in the ever-evolving digital landscape. Commitment to transparency, collaboration, and continuous improvement,meaningful, impactful advertising strategies, fuel growth, elevating digital presence.'/>
      <meta name='keywords' content='Digital Advertising Agency, Paid Media Services, Programmatic Advertising, Branding Campaigns, Digital Marketing Solutions, Online Advertising Experts, Social Media Advertising, PPC Management, Remarketing Services, WhatsApp Automation for Brands, Data-Driven Advertising, Digital Ad Campaign Optimization, Performance Marketing Agency, Video and Display Advertising' />
    </Helmet>
 <header className="relative h-96 bg-cover bg-center rounded-lg shadow-lg mt-4"
          style={{ backgroundImage: `url('/assets/aboutbg.png')` }}
        >
        <div className="absolute inset-0 bg-black opacity-60 rounded-lg"></div>
        <div className="relative z-10 flex flex-col justify-center items-center text-center h-full">
          <h1 className="text-white text-4xl md:text-6xl font-bold mb-4 animate-pulse ">Discover Us</h1>
          <p className="text-white text-lg md:text-2xl mb-8   max-w-3xl mx-auto leading-relaxed ">
          Empowering brands with data-driven strategies, creative innovation, and measurable results to thrive in the digital world.
          </p>
          <Link to ='/contact-us'>
          <div
            className="text-white bg-orange hover:bg-orange font-semibold px-6 py-3 rounded-full shadow-lg transition-transform transform hover:scale-105"
          >
            Get Started
          </div>
          </Link>
        </div>
      </header>
<div className="bg-dark  dark:text-white min-h-screen px-6 py-2">
      {/* Hero Section */}
      <section className="max-w-7xl mx-auto text-center space-y-12 mt-20 px-4">
  {/* Subtitle */}
  <p className="text-2xl  text-gray-300">
    Our journey is driven by passion, creativity and the will to make an impact.
  </p>

  {/* Card Grid */}
  <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 items-center justify-center">
    
    {/* Card 1: Our Mission */}
    <div className="group relative overflow-hidden rounded-lg shadow-lg p-8 bg-gradient-to-r from-green-400 to-blue-500  hover:shadow-2xl hover:scale-105 transition-all duration-500 ease-in-out transform-gpu">
      {/* Background Blob */}
      <div className="absolute inset-0 bg-gradient-to-r from-green-400 to-blue-500 opacity-70 group-hover:animate-spin-slow"></div>
      
      <h2 className="relative z-10 text-3xl font-bold text-white mb-4">Our Mission</h2>
      <p className="relative z-10 text-gray-100 text-base leading-relaxed">
        Our mission is to empower businesses by creating innovative, data-driven marketing solutions that enhance brand visibility.
      </p>
    </div>

    {/* Card 2: Our Vision */}
    <div className="group relative overflow-hidden rounded-lg shadow-lg p-8 bg-gradient-to-r from-pink-500 to-red-500 hover:shadow-2xl hover:scale-105 transition-all duration-500 ease-in-out transform-gpu">
      {/* Background Blob */}
      <div className="absolute inset-0 bg-gradient-to-r from-pink-500 to-red-500 opacity-70 group-hover:animate-pulse"></div>

      <h2 className="relative z-10 text-3xl font-bold text-white mb-4">Our Vision</h2>
      <p className="relative z-10 text-gray-100 text-base leading-relaxed">
        The vision of our agency is to empower brands to thrive in the digital landscape by delivering innovative, data-driven solutions to our clients.
      </p>
    </div>
  </div>
</section>



      {/* Values Section */}
      <section className="max-w-7xl mx-auto text-center space-y-8 py-12">
  {/* Title */}
  <h2 className="text-3xl md:text-4xl font-bold tracking-tight">
    Our Values
  </h2>
  <p className="text-lg max-w-3xl mx-auto">
    Our core values guide everything we do from integrity to excellence, these principles shape our approach to business.
  </p>

  {/* Cards Grid */}
  <div className="flex flex-col md:flex-row justify-center gap-8 items-center">

    {/* Card 1: Integrity */}
    <div className="group relative overflow-hidden rounded-lg shadow-lg p-8 bg-gradient-to-r from-yellow-400 to-red-400 transition-transform hover:scale-105 hover:shadow-2xl duration-500 ease-in-out w-full md:w-1/4">
      {/* Icon */}
      <div className="text-4xl mb-4 text-white group-hover:animate-bounce">
        🛡️ {/* Integrity Icon */}
      </div>
      <h3 className="text-xl font-semibold text-white mb-4">Integrity</h3>
      <p className="text-white">
        We believe in honesty and transparency in all our interactions.
      </p>
    </div>

    {/* Card 2: Innovation */}
    <div className="group relative overflow-hidden rounded-lg shadow-lg p-8 bg-gradient-to-r from-purple-400 to-pink-400 transition-transform hover:scale-105 hover:shadow-2xl duration-500 ease-in-out w-full md:w-1/4">
      {/* Icon */}
      <div className="text-4xl mb-4 text-white group-hover:animate-bounce">
        🚀 {/* Innovation Icon */}
      </div>
      <h3 className="text-xl font-semibold text-white mb-4">Innovation</h3>
      <p className="text-white">
        Embracing creativity and forward-thinking is at the heart of our agency.
      </p>
    </div>

    {/* Card 3: Excellence */}
    <div className="group relative overflow-hidden rounded-lg shadow-lg p-8 bg-gradient-to-r from-indigo-400 to-purple-500 transition-transform hover:scale-105 hover:shadow-2xl duration-500 ease-in-out w-full md:w-1/4">
      {/* Icon */}
      <div className="text-4xl mb-4 text-white group-hover:animate-bounce">
        ⭐ 
      </div>
      <h3 className="text-xl font-semibold text-white mb-4">Excellence</h3>
      <p className="text-white">
        We are committed to the highest standards of quality in our work.
      </p>
    </div>
  </div>
</section>


      
      
      <div className="min-h-screen text-white">
        {/* Header Section */}
        <div className="container mx-auto lg:px-12 py-16 flex flex-col md:flex-row items-center">
          <div className="md:w-1/2">
      
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mt-4">
            Our team is a dynamic group of digital marketing experts, strategists and creatives committed to driving results.
            </h1>
            <p className="text-gray-400 mt-4 text-lg mr-4">
            With diverse skills in Branding Campaigns, Performance Marketing, Paid Media, Programmatic Advertising, WhatsApp Automation, social media, content creation, and analytics, we work collaboratively to deliver innovative solutions tailored to your business.
            </p>
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
            <img
              src="/assets/about11.jpeg"
              alt="SEO Illustration"
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>
        </div>

        <div className="min-h-screen text-white">
        {/* Header Section */}
        <div className="container mx-auto lg:px-14 py-16 flex flex-col md:flex-row items-center gap-2">
        <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
            <img
              src="/assets/about12.jpeg"
              alt="SEO Illustration"
              className="rounded-lg shadow-lg"
            />
          </div>
          <div className="md:w-1/2 ml-4">
           
            
            <h1 className="text-3xl md:text-3xl lg:text-4xl font-bold mt-4">
            Experts Who Drive Success             </h1>
            <p className="text-gray-400 mt-4 text-lg mr-4">
            Each member brings unique expertise and a passion for staying ahead of industry trends, ensuring that we consistently exceed client expectations and achieve measurable success. Together, we turn ideas into impactful campaigns that make your brand stand out.
            </p>
          </div>
          
        </div>
        </div>



           
   
    </div>

    
     
  




     
    
    </>
  )
}

export default About
