import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { motion } from "framer-motion"; // For animations

const BlogPost = () => {
  const { id } = useParams(); // `id` here is actually the `content_url`
  const [blog, setBlog] = useState(null);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchBlog = async () => {
      const response = await fetch("https://test.thefinmaster.com/console/react-app-directory/test-blog-api.php");
      const data = await response.json();
      const selectedBlog = data.blogs.find((b) => b.content_url === id); // Find by `content_url`
      setBlog(selectedBlog);
      setLoading(false); // Stop loading when data is fetched
    };

    const fetchRecentBlogs = async () => {
      const response = await fetch("https://test.thefinmaster.com/console/react-app-directory/test-blog-api.php");
      const data = await response.json();

      // Sort recent blogs by date (newest first)
      const sortedRecentBlogs = data.blogs
        .sort((a, b) => new Date(b.content_date) - new Date(a.content_date))
        .slice(0, 10); // Fetch only the first 10 blogs for recent posts

      setRecentBlogs(sortedRecentBlogs);
    };

    fetchBlog();
    fetchRecentBlogs();
  }, [id]);

  if (loading) {
    return <Skeleton height={300} count={3} />;
  }

  return (
    <div className="container mx-auto p-4 py-10 bg-gray-900 text-gray-100">
      <div className="flex flex-wrap px-4">
        <div className="w-full md:w-3/4 pr-6 py-14">
          <motion.img
            src={blog.content_image}
            alt={blog.content_title}
            className="w-full h-96 object-cover mb-4 rounded-lg shadow-lg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          />
          <motion.h1
            className="text-4xl font-bold mb-4 text-pink-500"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {blog.content_title}
          </motion.h1>
          <div className="flex items-center text-gray-400 text-sm mb-4">
            <span className="mr-4">
              <i className="fa fa-calendar-alt font-bold text-lg"></i> Published: {new Date(blog.content_date).toLocaleDateString()}
            </span>
          </div>
          <motion.p
            className="text-gray-300 text-base mb-6"
            dangerouslySetInnerHTML={{ __html: blog.content_description }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          ></motion.p>
        </div>

        {/* Sidebar */}
        <div className="w-full md:w-1/4 py-10 bg-gray-800 p-4 rounded-lg shadow-lg mt-14">
          <h3 className="text-xl font-bold mb-4 border-b-2 border-pink-500 pb-2">Recent Posts</h3>
          <ul>
            {recentBlogs.map((recentBlog) => (
              <motion.li
                key={recentBlog.id}
                className="mb-4"
                initial={{ opacity: 0, x: -30 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <a href={`/blog/${recentBlog.content_url}`} className="flex hover:bg-gray-700 p-2 rounded-lg transition-all duration-300">
                  <img src={recentBlog.content_image} alt={recentBlog.content_title} className="w-16 h-16 object-cover mr-4 rounded-lg" />
                  <div>
                    <h4 className="font-semibold text-gray-100 hover:text-pink-500 transition-colors">
                      {recentBlog.content_title}
                    </h4>
                    <p className="text-gray-400 text-sm">
                      {new Date(recentBlog.content_date).toLocaleDateString()}
                    </p>
                  </div>
                </a>
              </motion.li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
