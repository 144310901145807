import './App.css';
import About from './components/AboutPage/About';
import Home from './components/HomePage/Home';
import Navbar from './components/Navbar';
import { Route, Routes, useLocation } from 'react-router-dom';
import Service from './components/Service/Service';
import Footer from './components/Footer';
import Contact from './components/Contact/Conatct';
import BlogPage from './components/Blogs/BlogPage';
import BlogPost from './components/Blogs/BlogPost';
import { useEffect } from 'react';
import TermsAndConditions from './components/Policy/TermAndCondition';
import PrivacyPolicy from './components/Policy/PrivacyPolicy';
import CustomCursor from './components/Cursor/CustomCursor';

function App() {
  const location = useLocation()
  useEffect(() => {
  
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
    <div className='bg-dark' >
      <CustomCursor />
      <Navbar />
      <Routes>
        <Route path='/' exact element={<Home />}/>
        <Route path='/about-us' element={<About />}/>
        <Route path='/service' element={<Service />}/>
        <Route path='/contact-us' element={<Contact />}/>
        <Route path='/blog' element={<BlogPage/>} />
        <Route path="/blog/:id" element={<BlogPost />} />
        <Route path='/term-and-condition' element={<TermsAndConditions />}/>
        <Route path='/privacy-policy' element={<PrivacyPolicy />}/>

      </Routes>
      <Footer />
    </div>
    </>
  );
}

export default App;
