import React, { useState } from 'react';
import Http from "../../Http";
import { Contact_Us_API } from "../../Helper";
import { Helmet } from 'react-helmet';

const ContactDemo = () => {
  const initialFormData = {
    name: "",
    email: "",
    subject: "",
    message: "",
    phone: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'name') {
      const pattern = /^[A-Za-z\s]+$/;
      if (!pattern.test(value)) {
        setError("Name should contain only letters and spaces.");
      } else {
        setError(null);
      }
    }
  
    if (name === 'email') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(value)) {
        setError("Please enter a valid email address.");
      } else {
        setError(null);
      }
    }
  
     // Validation for phone number (accepts only numeric input with 10 to 12 digits)
  if (name === 'phone') {
    const phonePattern = /^\d{10,12}$/; // Accepts numeric input with 10 to 12 digits
    if (!phonePattern.test(value)) {
      setError("Please enter a valid phone number with 10 to 12 digits.");
    } else {
      setError(null);
    }
  }

  
  

    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

   
    if (!formData.name.trim() || !formData.phone.trim() || !formData.email.trim() || !formData.subject.trim() || !formData.message.trim()) {
      setError("Please fill out all fields.");
      return;
    }

    
    if (error) {
      return;
    }

    setLoading(true);

    try {
      const response = await Http.post(Contact_Us_API, formData);
      console.log("Response:", response);
      setSuccess(true);
      resetForm();
    } catch (error) {
      console.error("Error sending data:", error);
      setError("Failed to send data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
  };
  return (
    <>
     <Helmet>
  <title>Troupe Digital Customer Care Toll Free Numbers & Contact Details</title>
  <meta  name='description' content='Get Troupe Digital contact details, customer care helpline number & toll free numbers in case you require any assistance. You can call us at Troupe Digital toll free number for any help.'/>
</Helmet>
 <header className="relative h-96 bg-cover bg-center rounded-lg shadow-lg mt-4"
style={{ backgroundImage: `url('/assets/contact.png')` }}        >
        {/* Dark Overlay */}
        <div className="absolute inset-0 bg-black opacity-60 rounded-lg"></div>

        {/* Header Content */}
        <div className="relative z-10 flex flex-col justify-center items-center text-center h-full">
          <h1 className="text-white text-4xl md:text-6xl font-bold mb-4 animate-pulse ">Contact Us</h1>
          <p className="text-white text-lg md:text-2xl mb-8">
          We'd love to hear from you whether you have questions or need support, feel free to reach out.
          </p>
         
        </div>
      </header>

    {/* <div className="min-h-screen bg-gray-900 text-white"> */}
      {/* Contact Us Banner */}
      {/* <div className="w-full bg-gray-900 py-20 px-8 text-center">
        <h1 className="text-4xl font-bold mb-4 animate-fadeInDown">Contact Us</h1>
        <p className="text-lg animate-fadeInUp">
          We'd love to hear from you. Whether you have questions or need support, feel free to reach out.
        </p>
      </div> */}
<div className="min-h-screen bg-dark text-white flex items-center justify-center p-8">
  {/* Contact Section Wrapper */}
  <div className="w-full max-w-7xl flex flex-col md:flex-row items-center justify-between space-y-12 md:space-y-0 md:space-x-8">
    {/* Left Section */}
    <div className="md:w-1/2 space-y-6 text-gray-300 animate-fadeInLeft">
      <h2 className="text-4xl font-bold text-orange-500 mb-4">Elevate Your Brand</h2>
      <p className="text-lg leading-relaxed">
        Ready to elevate your brand’s digital presence? Get in touch with our expert team for customized marketing strategies designed to grow your business. Whether you need social media, or content marketing, we’re here to help. Contact us today to start driving measurable results!
      </p>
      <p className="text-lg leading-relaxed">
      Elevate your brand with cutting-edge strategies, blending data-driven insights and creative storytelling to captivate audiences, boost engagement, and drive impactful results across digital platforms.
      </p>
    </div>
    {/* Right Section - Form */}
    <div className="md:w-1/2 bg-dark p-8 rounded-lg shadow-lg animate-fadeInRight">
      <h2 className="text-3xl font-bold text-white mb-6">Get In Touch</h2>
      <p className="text-gray-400 mb-8">
        Whether you're looking for strategic support or have a quick question, we’re here to help you every step of the way.
      </p>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex flex-col space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-semibold text-gray-300">Name</label>
            <input 
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
              className="w-full mt-2 px-4 py-3 bg-gray-700 border-none rounded focus:ring-2 focus:ring-orange transition duration-300"
              required
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-semibold text-gray-300">Email</label>
            <input 
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className="w-full mt-2 px-4 py-3 bg-gray-700 border-none rounded focus:ring-2 focus:ring-orange transition duration-300"
              required
           />
          </div>

          <div>
            <label htmlFor="phone number" className="block text-sm font-semibold text-gray-300">Phone Number</label>
            <input 
               type="tel"
               name="phone"
               placeholder="Phone Number"
               value={formData.phone}
               onChange={handleChange}
               onKeyDown={(e) => {
               if (!/\d/.test(e.key) && e.key !== 'Backspace') {
              e.preventDefault();
              }
              }}
              className="w-full mt-2 px-4 py-3 bg-gray-700 border-none rounded focus:ring-2 focus:ring-orange transition duration-300"
              required
            />
          </div>

          <div>
            <label htmlFor="subject" className="block text-sm font-semibold text-gray-300">Subject</label>
            <input 
                type="text"
                name="subject"
                placeholder='Subject'
                value={formData.subject}
                onChange={handleChange}
              className="w-full mt-2 px-4 py-3 bg-gray-700 border-none rounded focus:ring-2 focus:ring-orange-500 transition duration-300"
            />

          </div>
          <div>
            <label htmlFor="subject" className="block text-sm font-semibold text-gray-300">Messege</label>
            <textarea 
                 name="message"
                 value={formData.message}
                 onChange={handleChange}
                 placeholder='Write Your Messege'
              className="w-full mt-2 px-4 py-3 bg-gray-700 border-none rounded focus:ring-2 focus:ring-orange-500 transition duration-300"
              rows="4"
            ></textarea>

          </div>
        </div>

        <button 
           type="submit"
            className="w-full py-3 bg-orange rounded text-white font-bold hover:bg-orange transition-all duration-300"
            disabled={loading}>
            {loading ? "Sending..." : "Submit "}
           
           
          </button>
      </form>
      {error && <p>{error}</p>}
      {success && <p>We have received your query. Our team will contact you soon</p>}
    </div>
  </div>
</div>

    
    <section className="py-10 bg-dark sm:py-16 lg:py-24">
    <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-5xl">Questions & Answers</h2>
            <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-300">Explore the common questions and answers about Celebration</p>
        </div>

        <div className="grid grid-cols-1 mt-12 md:mt-20 md:grid-cols-2 gap-y-16 gap-x-20">
            <div className="flex items-start">
                <div className="flex items-center justify-center flex-shrink-0 w-8 h-8 bg-gray-700 rounded-full">
                    <span className="text-lg font-semibold text-orange">?</span>
                </div>
                <div className="ml-4">
                    <p className="text-xl font-semibold text-white">What do we help you achieve?</p>
                    <p className="mt-4 text-base text-gray-400">By Increasing brand awareness, boost sales, improve online presence for getting noticed. We help you achieve increased brand visibility, targeted audience engagement, higher conversion rates, and measurable growth.</p>
                </div>
            </div>

            <div className="flex items-start">
                <div className="flex items-center justify-center flex-shrink-0 w-8 h-8 bg-gray-700 rounded-full">
                    <span className="text-lg font-semibold text-orange">?</span>
                </div>
                <div className="ml-4">
                    <p className="text-xl font-semibold text-white">How can we your improve your social media presence? </p>
                    <p className="mt-4 text-base text-gray-400">We can help improve your social media presence by creating tailored strategies to increase engagement, grow your audience, and optimize content for better visibility.</p>
                </div>
            </div>

            <div className="flex items-start">
                <div className="flex items-center justify-center flex-shrink-0 w-8 h-8 bg-gray-700 rounded-full">
                    <span className="text-lg font-semibold text-orange">?</span>
                </div>
                <div className="ml-4">
                    <p className="text-xl font-semibold text-white">What service do we offer for E-commerce businesses?  </p>
                    <p className="mt-4 text-base text-gray-400">We offers services like  advertising, social media marketing, email campaigns, and conversion optimization to boost traffic and sales for e-commerce businesses.</p>
                </div>
            </div>

            <div className="flex items-start">
                <div className="flex items-center justify-center flex-shrink-0 w-8 h-8 bg-gray-700 rounded-full">
                    <span className="text-lg font-semibold text-orange">?</span>
                </div>
                <div className="ml-4">
                    <p className="text-xl font-semibold text-white">How do we measure the success of the marketing campaigns? </p>
                    <p className="mt-4 text-base text-gray-400">In order for any additional comments or requests reach us directly through out contact us. We measures the success of its campaigns using key metrics like  website traffic, conversion rates, and engagement across platforms.</p>
                </div>
            </div>
        </div>

       
    </div>
</section>


    </>
  );
};

export default ContactDemo;
