import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'; 
import { motion } from "framer-motion"; 

const BlogMain = () => {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage] = useState(5); // Set to 5 blogs per page

  useEffect(() => {
    const fetchBlogs = async () => {
      const response = await fetch("https://test.thefinmaster.com/console/react-app-directory/test-blog-api.php");
      const data = await response.json();
      setBlogs(data.blogs);

      const categoryMap = {};
      data.blogs.forEach(blog => {
        const category = blog.content_category;
        if (category) {
          categoryMap[category] = (categoryMap[category] || 0) + 1;
        }
      });
      const categoryArray = Object.keys(categoryMap).map(category => ({
        name: category,
        count: categoryMap[category]
      }));
      setCategories(categoryArray);
      setLoading(false);
    };

    fetchBlogs();
  }, []);

  const handleCategoryClick = (categoryName) => {
    setSelectedCategory(categoryName);
    setCurrentPage(1); // Reset to the first page on category change
  };

  const filteredBlogs = blogs
    .filter((blog) => selectedCategory === null || blog.content_category === selectedCategory)
    .sort((a, b) => new Date(b.content_date) - new Date(a.content_date));

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const totalPages = Math.ceil(filteredBlogs.length / blogsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen">
      {/* Header Section */}
      <header className="relative h-96 bg-cover bg-center rounded-lg shadow-lg"
        style={{ backgroundImage: "url('https://i0.wp.com/www.esearchadvisors.com/blog/wp-content/uploads/2018/12/Blog-banner-1.jpg?fit=1903%2C549&ssl=1')" }}
        >
        <div className="absolute inset-0 bg-black opacity-60 rounded-lg"></div>
        <div className="relative z-10 flex flex-col justify-center items-center text-center h-full">
          <h1 className="text-white text-4xl md:text-6xl font-bold mb-4 animate-pulse">Discover Our Blogs</h1>
          <p className="text-white text-lg md:text-2xl mb-8">
            Explore insightful posts on design, development, and more.
          </p>
          <div
            className="text-white bg-orange hover:bg-orange font-semibold px-6 py-3 rounded-full shadow-lg transition-transform transform hover:scale-105"
          >
            Get Started
          </div>
        </div>
      </header>

      {/* Main Blog Section */}
      <main className="container mx-auto px-4 py-16">
        <div className="flex flex-wrap -mx-4">
          {/* Blog Posts Section */}
          <section className="w-full md:w-3/4 px-4 mb-16">
            {loading ? (
              <Skeleton height={300} count={3} />
            ) : currentBlogs.length === 0 ? (
              <div className="text-center text-gray-400">No blogs found for this category.</div>
            ) : (
              currentBlogs.map((blog) => (
                <motion.div
                  key={blog.id}
                  className="mb-12 p-6 bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                >
                  {/* Blog Image */}
                  <img
                    src={blog.content_image}
                    alt={blog.content_title}
                    className="w-full h-64 object-cover rounded-t-lg transition-transform transform hover:scale-105 duration-300"
                  />

                  {/* Blog Content */}
                  <div className="mt-4">
                    <div className="flex items-center text-gray-400 text-sm mb-2">
                      <span><i className="fa fa-calendar-alt"></i> {blog.content_date}</span>
                    </div>
                    <h2 className="text-2xl font-semibold text-gray-100 hover:text-orange transition-colors duration-300">
                      {blog.content_title}
                    </h2>
                    {/* <p className="text-gray-400 mt-2">
                      {blog.content_description.slice(0, 150)}...
                    </p> */}
                    {/* <p className="text-gray-400 mt-2"
   dangerouslySetInnerHTML={{ __html: blog.content_description.slice(0, 150)  }}>
</p> */}
                    <a
                      href={`/blog/${blog.content_url}`}
                      className="inline-block mt-4 text-white bg-orange hover:bg-orange font-semibold px-4 py-2 rounded-full shadow-lg transition-transform transform hover:scale-105"
                    >
                      Read More
                    </a>
                  </div>
                </motion.div>
              ))
            )}

            {/* Pagination */}
            {filteredBlogs.length > blogsPerPage && (
              <div className="flex justify-center space-x-2 mt-8">
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => paginate(index + 1)}
                    className={`px-4 py-2 rounded-full ${currentPage === index + 1 ? 'bg-orange text-white' : 'bg-gray-700 text-gray-400 hover:bg-gray-600'}`}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            )}
          </section>

          {/* Sidebar Section */}
          <aside className="w-full md:w-1/4 px-4">
            {/* Search Bar */}
            <div className="mb-8 bg-gray-800 rounded-lg shadow-lg p-4">
              <input
                type="text"
                placeholder="Search..."
                className="w-full p-3 border rounded-lg border-gray-600 bg-gray-700 text-gray-100 focus:outline-none focus:ring-2 focus:ring-pink-500"
              />
              <button className="mt-4 w-full bg-orange text-white p-2 rounded-lg shadow-lg hover:bg-orange transition-colors">
                <i className="fa fa-search"></i> Search
              </button>
            </div>

            {/* Categories */}
            <div className="mb-8 bg-gray-800 rounded-lg shadow-lg p-4">
              <h3 className="text-xl font-semibold mb-4 border-b border-gray-600 pb-2">Categories</h3>
              <ul className="space-y-3">
                <li>
                  <button
                    onClick={() => setSelectedCategory(null)}
                    className={`w-full text-left px-4 py-2 rounded-lg font-medium transition-colors ${selectedCategory === null ? 'bg-pink-100 text-orange' : 'text-gray-100 hover:bg-gray-700'}`}
                  >
                    All Categories
                  </button>
                </li>
                {loading ? (
                  <Skeleton count={5} height={30} />
                ) : (
                  categories.map((category) => (
                    <li key={category.name}>
                      <button
                        onClick={() => handleCategoryClick(category.name)}
                        className={`w-full text-left px-4 py-2 rounded-lg font-medium transition-colors ${selectedCategory === category.name ? 'bg-pink-100 text-orange' : 'text-gray-100 hover:bg-gray-700'}`}
                      >
                        {category.name} <span className="text-gray-400">({category.count})</span>
                      </button>
                    </li>
                  ))
                )}
              </ul>
            </div>

            {/* Recent News */}
            <div className="bg-gray-800 rounded-lg shadow-lg p-4">
              <h3 className="text-xl font-semibold mb-4 border-b border-gray-600 pb-2">Recent Blogs</h3>
              <ul className="space-y-4">
                {loading ? (
                  <Skeleton count={3} height={80} />
                ) : (
                  blogs.slice(0, 3).map((blog) => (
                    <motion.li
                      key={blog.id}
                      className="flex items-start"
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <img
                        src={blog.content_image}
                        alt={blog.content_title}
                        className="w-16 h-16 object-cover rounded-lg mr-4"
                      />
                      <div>
                        <h4 className="text-gray-100 font-semibold hover:text-orange transition-colors">
                          {blog.content_title}
                        </h4>
                        <p className="text-gray-400 text-sm">{blog.content_date}</p>
                      </div>
                    </motion.li>
                  ))
                )}
              </ul>
            </div>
          </aside>
        </div>
      </main>
    </div>
  );
};

export default BlogMain;
