import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-dark text-white py-10">
      <div className="container mx-auto px-4 lg:px-10">
        {/* Top section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 text-sm">
          {/* Logo and description */}
          <div>
            {/* <h2 className="text-2xl font-bold mb-4">Troupe Digital</h2> */}
            <a href='/'>
            <div className='text-white w-[130px] h-[55px] z-20'>
          <img className="w-full h-full object-contain" src='/logo.jpeg' alt='logo' />
        </div>
                    </a>
            <p className="mb-6">
            Amplifying your brand with custom strategies that engage and drive real results.
            </p>
            <div className="flex space-x-4">
      {/* Facebook */}
      <div className="bg-gray-700 p-3 rounded-full hover:bg-orange">
        <FaFacebookF className="text-white" />
      </div>

      {/* Twitter */}
      <div className="bg-gray-700 p-3 rounded-full hover:bg-orange">
        <FaTwitter className="text-white" />
      </div>

      {/* Instagram */}
      <div className="bg-gray-700 p-3 rounded-full hover:bg-orange">
        <FaInstagram className="text-white" />
      </div>

      {/* LinkedIn */}
      <div className="bg-gray-700 p-3 rounded-full hover:bg-orange">
        <FaLinkedinIn className="text-white" />
      </div>
    </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Link</h3>
            <ul className="space-y-3">
           
    <Link to='/'><div className="hover:text-orange">Home</div></Link>
              <Link to='/about-us'><div className="hover:text-orange">About</div></Link>
              <Link to='/service'><div className="hover:text-orange">Services</div></Link>
              <Link to='/contact-us'><div className="hover:text-orange">Contact</div></Link>
            </ul>
          </div>

          {/* Get in Touch */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Get In Touch</h3>
            <ul className="space-y-3">
              <li className="flex items-center">
                <i className="fas fa-map-marker-alt text-yellow-400 mr-2"></i> PLOT NUMBER 127, 4TH FLOOR
              </li>
              <li className="flex items-center">
                <i className="fas fa-envelope text-yellow-400 mr-2"></i>  SECTOR 44, GURUGRAM, Gurgaon
              </li>
              <li className="flex items-center">
                <i className="fas fa-phone-alt text-yellow-400 mr-2"></i> Haryana, 122003 
              </li>
            </ul>
          </div>

          {/* Newsletter */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Newsletter</h3>
            <p className="mb-4">Get more updates to join us</p>
            <div className="relative">
              <input
                type="email"
                placeholder="Email Address"
                className="w-full px-4 py-3 bg-gray-700 text-white rounded-full focus:outline-none"
              />
              <button className="absolute right-0 top-0 mt-2 mr-2 bg-orange hover:bg-orange text-white rounded-full p-3">
                <i className="fas fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>

        {/* Bottom section */}
        <div className="mt-10 border-t border-gray-700 pt-6 flex flex-col lg:flex-row justify-between text-sm text-gray-400">
          <ul className="flex space-x-6 mb-4 lg:mb-0">
            <Link to='/term-and-condition'><div className="hover:text-gray-200">Term And Condition</div></Link>
            <Link to='/privacy-policy'><div className="hover:text-gray-200">Privacy Policy</div></Link>
          </ul>
          <p>© 2024 - Troupe Digital, All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
