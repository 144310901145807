import React from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Service = () => {
    // const services = [
    //     {
    //         title: "Branding Campaign",
    //         description: "Crafting memorable stories across digital platforms.",
    //         image: "🔊",
    //     },
    //     {
    //         title: "Performance Marketing",
    //         description: "Boosting conversions with lower-funnel strategies.",
    //         image: "💻",
    //     },
    //     {
    //         title: "Paid Media",
    //         description: "Access exclusive inventories and Google, Meta, TikTok ads.",
    //         image: '/assets/paid.jpeg',
    //     },
    //     {
    //         title: "Programmatic Advertising",
    //         description: "Optimizing campaigns with our in-house tool, BidBox.",
    //         image: "📊",
    //     },
    //     {
    //         title: "WhatsApp Automation",
    //         description: "Engaging customers with personalized, automated messages.",
    //         image: "🔍",
    //     },
    //     // {
    //     //     title: "Content Marketing",
    //     //     description: "Focus on creating and distributing valuable content.",
    //     //     icon: "✍️",
    //     // }
    // ];
    const services1 = [
      {
        title: "Branding Campaigns",
        description: "Crafting memorable stories across digital platforms.",
        icon: "📢", // Loudspeaker for branding
        bgColor: "bg-gradient-to-r from-green-400 to-blue-500",
      },
      {
        title: "Performance Marketing",
        description: "Boosting conversions with lower-funnel strategies.",
        icon: "📈", // Upward chart for performance
        bgColor: "bg-gradient-to-r from-pink-500 to-red-500",
      },
      {
        title: "Paid Media",
        description: "Access exclusive inventories and Google, Meta, TikTok ads.",
        icon: "💰", // Moneybag for paid media
        bgColor: "bg-gradient-to-r from-yellow-400 to-red-400",
      },
      {
        title: "Programmatic Advertising",
        description: "Optimizing campaigns with our in-house tool, BidBox.",
        icon: "⚙️", // Gear for programmatic automation
        bgColor: "bg-gradient-to-r from-purple-400 to-pink-400",
      },
      {
        title: "WhatsApp Automation",
        description: "Engaging customers with personalized, automated messages.",
        icon: "💬", // Chat bubble for messaging
        bgColor: "bg-gradient-to-r from-indigo-400 to-purple-500",
      },
      {
        title: "CTV Advertising",
        description: "Delivers targeted video ads to viewers through streaming services on internet-connected TV.",
        icon: "📺", // Television for CTV Advertising
        bgColor: "bg-gradient-to-r from-teal-400 to-green-500",
      }
    ];
    
      const ServiceCard = ({ service }) => {
        return (
          <div className={`p-6 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-xl ${service.bgColor}`}>
            <div className="text-6xl mb-4 text-white">{service.icon}</div>
            <h2 className="text-2xl font-bold text-white mb-2">{service.title}</h2>
            <p className="text-gray-100">{service.description}</p>
          </div>
        );
      };      
  return (
    <>
     <Helmet>
      <title>Troupe Digital -Empowering Brands with Data-Driven Digital Advertising Solutions</title>
      <meta  name='description' content='WhatsApp automation strategic planning to campaign execution, optimization, and in-depth reporting, we ensure every step is data-driven, delivering results'/>
      <meta name='keywords' content='Digital Advertising Agency, Paid Media Services, Programmatic Advertising, Branding Campaigns, Digital Marketing Solutions, Online Advertising Experts, Social Media Advertising, PPC Management, Remarketing Services, WhatsApp Automation for Brands, Data-Driven Advertising, Digital Ad Campaign Optimization, Performance Marketing Agency, Video and Display Advertising' />
    </Helmet>
         <header className="relative h-96 bg-cover bg-center rounded-lg shadow-lg mt-4"
style={{ backgroundImage: `url('/assets/servicebg.png')` }}        >
        <div className="absolute inset-0 bg-black opacity-60 rounded-lg"></div>
        <div className="relative z-10 flex flex-col justify-center items-center text-center h-full">
          <h1 className="text-white text-4xl md:text-6xl font-bold mb-4 animate-pulse ">Our Services</h1>
          <p className="text-white text-lg md:text-2xl mb-8">
          Fueling your growth with precision campaigns, innovative strategies, and digital solutions that make an impact
          </p>
          <Link to ='/contact-us'>
          <div
            className="text-white bg-orange hover:bg-orange font-semibold px-6 py-3 rounded-full shadow-lg transition-transform transform hover:scale-105"
          >
            Get Started
          </div>
          </Link>
        </div>
      </header>
     <div className="min-h-screen bg-dark text-white flex items-center justify-center mt-20 ">
      {/* Container */}

      
      <div className="container mx-auto px-4 lg:px-8 flex flex-col lg:flex-row items-center ">
        {/* Left Side - Text */}
        <div className="lg:w-1/2 mb-8 lg:mb-0 lg:pr-12">
          {/* <h2 className="text-lg text-gray-400 mb-4">Welcome to Troupe Digital</h2> */}
          <h1 className="text-4xl lg:text-5xl font-bold leading-tight mb-6">
          Unlock Your Potential with Our Tailored Solutions
          </h1>
          <p className="text-gray-300 mb-8">
          At Troupe Digital, we specialize in providing comprehensive marketing services designed to elevate your brand's presence and drive results. Our tailored strategies focus on your unique needs, ensuring that we help you connect with your target audience and achieve your business goals. Let’s embark on this journey together!
          </p>
          <Link to ='/contact-us'>
          <button className="bg-orange hover:bg-orange text-white font-semibold py-3 px-8 rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105">
            Get Started
          </button>
          </Link>
        </div>

        {/* Right Side - Responsive Image */}
        <div className="lg:w-1/2">
          <img
            src="/assets/service.png" // Replace with actual image URL
            alt="Marketing Illustration"
            className="w-full max-w-lg h-auto rounded-lg shadow-xl transition-transform duration-500 hover:scale-110"
          />
        </div>
      </div>
    </div>

    <section className="max-w-7xl mx-auto text-center py-20 space-y-8">
        <h2 className="text-3xl md:text-4xl font-bold text-white">Our Secret Recipe for Success!</h2>
        {/* <p className="text-lg max-w-3xl mx-auto text-white">
          We follow a streamlined process to ensure the best outcomes for our clients.
        </p> */}
        <div className="flex flex-col md:flex-row justify-center items-center gap-6 text-gray-400">
          <div className="w-full md:w-1/4 rounded-lg p-6 bg-gray-800 dark:bg-gray-700 hover:bg-gray-800  transform transition-transform duration-300 hover:scale-105">
            <h3 className="text-xl font-semibold mb-2 text-white">01. Discovery & Consultation</h3>
            <p>What you need, we’re here to explore together every step of the way!</p>
          </div>
          <div className="w-full md:w-1/4 rounded-lg p-6 bg-gray-800 dark:bg-gray-700 hover:bg-gray-800 transform transition-transform duration-300 hover:scale-105">
            <h3 className="text-xl font-semibold mb-2 text-white">02. Strategic Planning</h3>
            <p>Developing tailored marketing strategies with clear objectives analyzing market trends.</p>
          </div>
          <div className="w-full md:w-1/4 rounded-lg p-6 bg-gray-800 dark:bg-gray-700 hover:bg-gray-800 transform transition-transform duration-300 hover:scale-105">
            <h3 className="text-xl font-semibold mb-2 text-white">03. Execution & Optimization</h3>
            <p>Implementing strategies while continuously optimizing for performance.</p>
          </div>
          <div className="w-full md:w-1/4 rounded-lg p-6 bg-gray-800 dark:bg-gray-700 hover:bg-gray-800 transform transition-transform duration-300 hover:scale-105">
            <h3 className="text-xl font-semibold mb-2 text-white">04. Analysis & Reporting</h3>
            <p>Providing detailed reports to assess campaign effectiveness data-driven insights into campaign.</p>
          </div>
        </div>
      </section>
      
     {/* <section className="bg-black py-16">
            <div className="text-center mb-10 ">
                <h2 className="text-white text-4xl font-bold mt-20">Our Comprehensive Services</h2>
                <p className="text-gray-400 text-2xl mt-4">
                Empowering brands through innovative marketing solutions
                </p>
            </div>

            <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
                {services.map((service, index) => (
                    <div
                        key={index}
                        className="bg-gray-900 p-6 rounded-lg shadow-lg text-center hover:bg-gray-800 transition duration-300"
                    >
            <img src={service.image} alt={service.title} className="mb-4 w-full h-48 object-cover rounded-lg" />
            <h3 className="text-xl font-bold text-white mb-4 hover:text-orange">{service.title}</h3>
                        <p className="text-gray-400">{service.description}</p>
                    </div>
                ))}
            </div>
        </section> */}
      
        <div className="min-h-screen bg-dark text-white py-16">
      <div className="container mx-auto px-4 lg:px-8">
        {/* Title */}
        <h1 className="text-5xl font-bold text-center mb-12">Our Services</h1>

        {/* Services Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {services1.map((service, index) => (
            <ServiceCard key={index} service={service} />
          ))}
        </div>
      </div>
    </div>


    <section className="py-10 bg-dark sm:py-16 lg:py-24">
    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-5xl">Why Choose Us?</h2>
            <p className="max-w-lg mx-auto mt-4 text-base leading-relaxed text-gray-400">
                We don’t just deliver results; we build partnerships. At Troupe Digital, we believe that collaboration and transparency are the foundations of success. Here’s why businesses trust us to take their digital presence to the next level:
            </p>
        </div>

        <div className="relative mt-12 lg:mt-20">
  {/* Curved line for first row with animation */}
  <div className="absolute inset-x-0 top-2 md:block hidden md:px-20 lg:px-28">
    <img
      className="w-full transition-all duration-700 transform hover:scale-105"
      src="/assets/curvedline.svg"
      alt="Curved Line"
    />
  </div>

  {/* First row of content */}
  <div className="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12">
    {/* Block 1 */}
    <div className="group">
      <div className="flex items-center justify-center w-16 h-16 mx-auto bg-gradient-to-r from-green-400 to-blue-500  border-2 border-gray-200 rounded-full shadow-lg transition-transform group-hover:scale-110 duration-500">
        <span className="text-xl font-semibold text-white animate-pulse">1</span>
      </div>
      <h3 className="mt-6 text-xl font-semibold leading-tight text-white md:mt-10 transition-colors duration-500 group-hover:text-orange">
        Proven Expertise
      </h3>
      <p className="mt-4 text-base text-gray-400 transition-opacity duration-700 group-hover:opacity-100">
        With years of experience in the digital marketing landscape, we’ve honed our skills in delivering measurable results across industries.
      </p>
    </div>

    {/* Block 2 */}
    <div className="group">
      <div className="flex items-center justify-center w-16 h-16 mx-auto bg-gradient-to-r from-pink-500 to-red-500 border-2 border-gray-200 rounded-full shadow-lg transition-transform group-hover:scale-110 duration-500">
        <span className="text-xl font-semibold text-white animate-pulse">2</span>
      </div>
      <h3 className="mt-6 text-xl font-semibold leading-tight text-white md:mt-10 transition-colors duration-500 group-hover:text-orange">
        Tailored Strategies
      </h3>
      <p className="mt-4 text-base text-gray-400 transition-opacity duration-700 group-hover:opacity-100">
        No cookie-cutter approaches here. We design campaigns specifically for your business goals, ensuring maximum impact.
      </p>
    </div>

    {/* Block 3 */}
    <div className="group">
      <div className="flex items-center justify-center w-16 h-16 mx-auto bg-gradient-to-r from-yellow-400 to-red-400  border-2 border-gray-200 rounded-full shadow-lg transition-transform group-hover:scale-110 duration-500">
        <span className="text-xl font-semibold text-white animate-pulse">3</span>
      </div>
      <h3 className="mt-6 text-xl font-semibold leading-tight text-white md:mt-10 transition-colors duration-500 group-hover:text-orange">
        Cutting-Edge Technology
      </h3>
      <p className="mt-4 text-base text-gray-400 transition-opacity duration-700 group-hover:opacity-100">
        From programmatic ad buying to AI-driven insights, we stay at the forefront of technology to optimize every aspect of your campaigns.
      </p>
    </div>
  </div>

  {/* Curved line for second row with animation */}
  <div className="absolute inset-x-0 bottom-40 md:block hidden md:px-20 lg:px-28">
    <img
      className="w-full transition-all duration-700 transform hover:scale-105"
      src="/assets/curvedline.svg"
      alt="Curved Line"
    />
  </div>

  {/* Second row of content */}
  <div className="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12 mt-12">
    {/* Block 4 */}
    <div className="group">
      <div className="flex items-center justify-center w-16 h-16 mx-auto bg-gradient-to-r from-purple-400 to-pink-400 border-2 border-gray-200 rounded-full shadow-lg transition-transform group-hover:scale-110 duration-500">
        <span className="text-xl font-semibold text-white animate-pulse">4</span>
      </div>
      <h3 className="mt-6 text-xl font-semibold leading-tight text-white md:mt-10 transition-colors duration-500 group-hover:text-orange">
        Full Transparency
      </h3>
      <p className="mt-4 text-base text-gray-400 transition-opacity duration-700 group-hover:opacity-100">
        We keep you informed every step of the way. Through detailed reporting and open communication.
      </p>
    </div>

    {/* Block 5 */}
    <div className="group">
      <div className="flex items-center justify-center w-16 h-16 mx-auto bg-gradient-to-r from-indigo-400 to-purple-500 border-2 border-gray-200 rounded-full shadow-lg transition-transform group-hover:scale-110 duration-500">
        <span className="text-xl font-semibold text-white animate-pulse">5</span>
      </div>
      <h3 className="mt-6 text-xl font-semibold leading-tight text-white md:mt-10 transition-colors duration-500 group-hover:text-orange">
        Dedicated Team
      </h3>
      <p className="mt-4 text-base text-gray-400 transition-opacity duration-700 group-hover:opacity-100">
        Our team is passionate about your success. We work alongside you to understand your brand, challenges, and goals.
      </p>
    </div>

    {/* Block 6 */}
    <div className="group">
      <div className="flex items-center justify-center w-16 h-16 mx-auto bg-gradient-to-r from-teal-400 to-green-500 border-2 border-gray-200 rounded-full shadow-lg transition-transform group-hover:scale-110 duration-500">
        <span className="text-xl font-semibold text-white animate-pulse">6</span>
      </div>
      <h3 className="mt-6 text-xl font-semibold leading-tight text-white md:mt-10 transition-colors duration-500 group-hover:text-orange">
        Deliverables
      </h3>
      <p className="mt-4 text-base text-gray-400 transition-opacity duration-700 group-hover:opacity-100">
        Our marketing agency delivers tailored strategies, measurable results, and impactful campaigns that drive brand growth and maximize ROI.
      </p>
    </div>
  </div>
</div>
</div>
</section>

    </>
  )
}

export default Service
